<template>
  <all-tv-shows />
</template>

<script>
  import AllTvShows from "./components/AllTvShows.vue";

  export default {
    components: { AllTvShows },
  };
</script>

<style>
</style>
